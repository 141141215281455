.header .header-logo {
  .logo {
    img {
      width: 257px;
      max-width: 250px;
    }
  }
}

.nav-sections-item-content .main-menu ul > li > a {
  font-size: 12px;
}

.sections.nav-sections {
  border-top: 1px solid #1A3D69;
}

.sections.nav-sections .nav-content {
  border-left: 1px solid #1A3D69;
}

.header .header-search .block-search .action.search {
  background: #52C9D1;
}

.action.primary:hover {
  background: #002859;
  border: 1px solid #002859;
  color: #fff;
}

.split-content .box-tocart {
  display: none;
}

.minicart-wrapper {

    .loading-mask {
        position: absolute;
        width: 15px;
        height: 14px;
        bottom: -10px;
        right: -6px;
        border-radius: 15px;
        background-color: #002859;

        .loader {
            position: relative;

            &:before {
                animation: fa-spin 2s linear infinite;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 700;
                content: "\f1ce";
                position: absolute;
                font-size: 1.3rem;
                color: #efefef;
                -webkit-filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5));
                filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5));
                top: -4px;
                right: 0px;
            }

            img {

                display: none;

            }
        }
    }
}

.firecheckout.checkout-index-index .header-account .continue-shopping {
  background: #52C9D1;
  border: 1px solid #52C9D1;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  padding: 7px 15px;
  font-size: 1.4rem;
  box-sizing: border-box;
  vertical-align: middle;
  text-transform: uppercase;
  border-radius: 8px;

  &:hover, &:focus {
      text-decoration: none;
      background: darken(#52C9D1, 10%);
  }
}

.header .header-logo {
  .logo {
    img {
      width: 278px;
      max-width: 250px;
    }
  }
}

.nav-sections-item-content .main-menu ul > li > a {
  font-size: 12px;
}

.sections.nav-sections {
  border-top: 1px solid #211E5B;
}

.sections.nav-sections .nav-content {
  border-left: 1px solid #211E5B;
}

.header .header-search .block-search .action.search {
  background: #3A5DAB;
}

.split-content .box-tocart {
  display: none;
}

.minicart-wrapper {

    .loading-mask {
        position: absolute;
        width: 15px;
        height: 14px;
        bottom: -10px;
        right: -6px;
        border-radius: 15px;
        background-color: #080549;

        .loader {
            position: relative;

            &:before {
                animation: fa-spin 2s linear infinite;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 700;
                content: "\f1ce";
                position: absolute;
                font-size: 1.3rem;
                color: #efefef;
                -webkit-filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5));
                filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5));
                top: -4px;
                right: 0px;
            }

            img {

                display: none;

            }
        }
    }
}

.firecheckout.checkout-index-index .header-account .continue-shopping {
  background: #3A5DAB;
  border: 1px solid #3A5DAB;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  padding: 7px 15px;
  font-size: 1.4rem;
  box-sizing: border-box;
  vertical-align: middle;
  text-transform: uppercase;
  border-radius: 8px;

  &:hover, &:focus {
      text-decoration: none;
      background: darken(#3A5DAB, 10%);
  }
}

.homepage--hero {

  .homepage--hero--wrapper {
      background: #2CB7C0;
      background: radial-gradient(circle, rgba(44,183,192,0.45) 0%, rgba(82,191,190,1) 100%);
  }

}